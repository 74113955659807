<template>
    <div class="number-grow-warp">
        <span ref="numberGrow" :data-time="time" :class="defaultClass" :data-value="valnumber"></span>
    </div>
</template>

<script>
export default {
    name: 'NumberGrow',
    props: {
        time: {
            type: Number,
            default: 10
        },
        valnumber: {
            type: Number,
            default: 0
        },
        defaultClass: {
            type: String,
            default: 'number-grow'
        }
    },
    data() {
        return {
            myInterval:null 
        }
    },
    watch: {
        valnumber(newVal, oldVal) {
            if(this.myInterval!=null)
            {
                clearInterval(this.myInterval)
            }
            let shortValue = (newVal - oldVal)
            let changeType = "add"

            if (shortValue < 0) {
                shortValue = -(shortValue)
                changeType = "subtraction"
            }

            let jumpTime = 145
            let jumpNumber = 1
            if (shortValue > 750) {
                jumpTime = 750 / shortValue

                let n = Math.round(shortValue / 750)

                jumpNumber = jumpNumber * n
                jumpTime = jumpTime * n

            } else if (shortValue > 30) {
                jumpTime = 750 / shortValue
            } else if (shortValue >= 20 && shortValue <= 30) {
                jumpTime = 700 / shortValue
            } else if (shortValue >= 10 && shortValue < 20) {
                jumpTime = 600 / shortValue
            } else if (shortValue >= 2 && shortValue < 10) {
                jumpTime = 530 / shortValue
            } else {
                jumpTime = 266
            }

            if (changeType === "add") {
                this.numberGrow(this.$refs.numberGrow, oldVal, jumpTime, jumpNumber, newVal)
            }
            else {
                this.numberReduce(this.$refs.numberGrow, oldVal, jumpTime, jumpNumber, newVal)
            }
        }
    },
    methods: {
        numberGrow(ele, oldVal, jumpTime, jumpNumber, newVal) {
            let _this = this
            let step = jumpNumber
            let current = oldVal
            let start = oldVal
            _this.myInterval = setInterval(function () {
                start += step
                if (start > newVal) {
                    clearInterval(_this.myInterval)
                    start = newVal
                    _this.myInterval = null
                }
                if (current === start) {
                    return
                }
                current = start
                ele.innerHTML = current
            }, jumpTime)
        },
        numberReduce(ele, oldVal, jumpTime, jumpNumber, newVal) {
            let _this = this
            let step = 0 - jumpNumber
            let current = oldVal
            let start = oldVal
            _this.myInterval = setInterval(function () {
                start += step
                if (start <= newVal) {
                    clearInterval(_this.myInterval)
                    start = newVal
                    _this.myInterval = null
                }
                if (current === start) {
                    return
                }
                current = start
                ele.innerHTML = current
            }, jumpTime)
        },
    },
    mounted() {
        this.$refs.numberGrow.innerHTML = this.valnumber
    }
}
</script>
<style scoped>
.number-grow-warp {
    transform: translateZ(0);
}

.number-grow {
    /* font-size: 1.3rem; */
    font-family: Arial;
    font-weight: 900;
    color: #ECE8F7;
}
</style>