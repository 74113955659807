<template>
    <div class="HeaderTopTip">
        <p class="tip">
            <a href="https://x.com/spacefi_io/status/1825837059573223725" target="_black">Join SpaceFi campaign in ZK Fest, earning 200k $ZK bonus!</a>
            <!-- <a href="https://twitter.com/spacefi_io/status/1775517003081740559?s=20" target="_black">SpaceFi 2.0 is Live now! </a>
            <router-link tag="a" to="/Bridge">Please migrate your assets from 1.0 through Bridge.</router-link> -->
            
        </p>
    </div>
</template>

<script>
export default {
    name: "HeaderTopTip",
    props: {
        
    },
    data() {
        return {

        }
    },
    created() { },
    mounted() { },
    methods: {

    }
}
</script>