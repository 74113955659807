import { SignerPaymaster } from "@holdstation/paymaster-helper"
import { ethers, } from "ethers";
import { Web3Provider } from "zksync-web3";
const PAYMASTER_ADDRESS = "0x069246dFEcb95A6409180b52C071003537B23c27";

export async function paymasterFunction(contractAddr,from,data) {
    try {
        const provider = new Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const nonce = await provider.getTransactionCount(from, 'latest')
        const chainId = (await provider.getNetwork()).chainId
        const populateTransaction = {
            from: from,
            to: contractAddr,
            data:data,
            chainId,
            value: 0,
            nonce,
        }
        const partnerCode = ethers.utils.formatBytes32String('SPACEFI');
        const tx = await SignerPaymaster.paymasterExecute({
            network: 'mainnet',
            paymentToken: localStorage.getItem(localStorage.getItem('userAddress')+'GASTokenAddress'),
            paymasterAddress: PAYMASTER_ADDRESS,
            innerInput: partnerCode,
            populateTransaction,
            signer,
        })
        const receipt = await tx.wait();
        return receipt
    } catch (error) {
        console.log(error)
        return error
    }
}