<template>
    <div class="noticeModel" v-show="noticeShow">
        <div class="connectBox connectBoxW" @click="closeNotice">
            <div class="notice0" @click.stop>
                <h1>
                    <p></p>
                    <i class="el-icon-close" @click="closeNotice"></i>
                </h1>
                <div class="info">
                    <p style="font-size:20px;">SpaceFi 2.0 is Live now!</p>
                    <p>A brand new product with sLP, boosted farm, paymaster and new STAR token / Planet NFT.</p>
                    <p>
                        If you have assets on SpaceFi 1.0, please migrate them from 
                        <span @click="closeNotice"><router-link tag="a" to="/Bridge" >Bridge </router-link></span>
                        and 
                        <span @click="closeNotice"><router-link tag="a" to="/Farm">Farm </router-link></span>
                    </p>
                    <p>Love to all ❤️</p>
                    <p><a href="https://twitter.com/spacefi_io/status/1775517003081740559?s=20" target="_blank" >Learn More >></a></p>
                    
                </div>
                
                <div class="NoMoreTips">
                    <el-checkbox v-model="checked" @change="changeCheck"> No more tips</el-checkbox> 
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Notice",
    data() {
        return {
            noticeShow:false,
            checked:false
        }
    },
    created() { },
    mounted() {
        this.checked = JSON.parse( localStorage.getItem('noticeShow') ) || false
        this.noticeShow = !JSON.parse( localStorage.getItem('noticeShow') ) || false
    },
    methods: {
        closeNotice(){
            this.noticeShow = false
        },
        changeCheck(){
            localStorage.setItem('noticeShow',this.checked)
        }
    }
}
</script>