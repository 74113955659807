import jazzicon from '@metamask/jazzicon';

if(typeof window.ethereum !== 'undefined'){
    window.ethereum.on('accountsChanged', function (accounts) {
        if(accounts.length == 0){
            localStorage.removeItem('userAddress');
        } else {
            localStorage.setItem('userAddress', accounts[0])
        }
        setTimeout(() => {     
            window.location.reload();
        }, 1500);
    })
}

export function metamaskIcon(userAddress,size){
    return new Promise((resolve, reject) => {
        const icon = jazzicon(size, parseInt(userAddress.slice(2,10), 16));
        resolve(icon)
    });
}
